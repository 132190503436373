// React
import React, { Fragment, useEffect, useState } from 'react'

// Headless UI
import { Combobox, Popover, Switch, Transition } from '@headlessui/react'

// Utils
import { useUserDetails } from '@/utils/auth/user-context'
import { supabaseBrowserClient as supabase } from '@/utils/supabase-browser-client'

// Types
import { SupportedLanguages } from '@supermeme-ai/types'

// External
import { usePopper } from 'react-popper'
import { isMobile } from 'react-device-detect'

// Icons
import {
  CheckIcon,
  ChevronUpDownIcon,
  LanguageIcon,
} from '@heroicons/react/24/outline'

const LanguageSelector: React.FC = () => {
  const [userDetails, setUserDetails] = useUserDetails()

  let [referenceElement, setReferenceElement] = useState()
  let [popperElement, setPopperElement] = useState()
  let { styles, attributes } = usePopper(referenceElement, popperElement)

  const [inputLanguageQuery, setInputLanguageQuery] = useState('')
  const [inputLanguage, setInputLanguage] = useState(
    SupportedLanguages.find(
      (lang) => lang.value === userDetails?.meme_input_language
    ) || { value: 'en', label: 'English' }
  )

  const [outputLanguageQuery, setOutputLanguageQuery] = useState('')
  const [outputLanguage, setOutputLanguage] = useState(
    SupportedLanguages.find(
      (lang) => lang.value === userDetails?.meme_output_language
    ) || { value: 'en', label: 'English' }
  )

  const [inputOutputLanguageEqual, setInputOutputLanguageEqual] = useState(
    userDetails?.meme_input_language === userDetails?.meme_output_language
  )

  const filteredInputLanguages =
    inputLanguageQuery === ''
      ? SupportedLanguages
      : SupportedLanguages.filter((language) =>
          language.label
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(inputLanguageQuery.toLowerCase().replace(/\s+/g, ''))
        )

  const filteredOutputLanguages =
    outputLanguageQuery === ''
      ? SupportedLanguages
      : SupportedLanguages.filter((language) =>
          language.label
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(outputLanguageQuery.toLowerCase().replace(/\s+/g, ''))
        )

  useEffect(() => {
    setInputLanguage(
      SupportedLanguages.find(
        (lang) => lang.value === userDetails?.meme_input_language
      )
    )
    setOutputLanguage(
      SupportedLanguages.find(
        (lang) => lang.value === userDetails?.meme_output_language
      )
    )
    setInputOutputLanguageEqual(
      userDetails?.meme_input_language === userDetails?.meme_output_language
    )
  }, [userDetails])

  return (
    <Popover className='relative'>
      <Popover.Button
        type='button'
        className='-ml-0.5 -mt-0.5 flex w-auto items-center justify-start space-x-0 rounded-md px-2 py-1 text-gray-600 hover:bg-gray-50 hover:text-gray-900 focus:outline-none md:w-72 md:space-x-2'
        ref={setReferenceElement as any}
      >
        <LanguageIcon className='h-5 w-5 md:h-8 md:w-8' aria-hidden='true' />
        <div className='text-left'>
          <span className='sr-only block font-bold md:not-sr-only'>
            Meme languages
          </span>
          <span className='sr-only flex items-center justify-center space-x-1 whitespace-nowrap text-xs md:not-sr-only'>
            <span className='inline-block max-w-[100px] truncate'>
              In:{' '}
              {SupportedLanguages.find(
                (lang) => lang.value === userDetails?.meme_input_language
              )?.label || 'English'}
            </span>
            <span>/</span>
            <span className='inline-block max-w-[100px] truncate'>
              Out:{' '}
              {SupportedLanguages.find(
                (lang) => lang.value === userDetails?.meme_output_language
              )?.label || 'English'}
            </span>
          </span>
        </div>
      </Popover.Button>

      <Popover.Panel
        className='absolute z-10 px-2 pt-1'
        ref={setPopperElement as any}
        {...attributes.popper}
        style={
          isMobile
            ? {
                ...styles.popper,
                transform: 'translate3d(-222px, 26px, 0px)',
              }
            : styles.popper
        }
      >
        <div className='mb-2 ml-2 w-72 space-y-4 rounded-md border border-gray-300 bg-gray-100 p-4'>
          <div className='space-y-1'>
            <label htmlFor='inputLanguage' className='text-sm'>
              Meme input language
            </label>
            <Combobox
              name='inputLanguage'
              value={inputLanguage}
              onChange={async (value) => {
                if (inputOutputLanguageEqual) {
                  setInputLanguage(value)
                  setOutputLanguage(value)
                  setUserDetails({
                    ...userDetails,
                    meme_input_language: value.value,
                    meme_output_language: value.value,
                  })

                  await supabase
                    .from('user_details')
                    .update({
                      meme_input_language: value.value,
                      meme_output_language: value.value,
                    })
                    .eq('id', userDetails?.id)
                } else {
                  setInputLanguage(value)
                  setUserDetails({
                    ...userDetails,
                    meme_input_language: value.value,
                  })

                  await supabase
                    .from('user_details')
                    .update({
                      meme_input_language: value.value,
                    })
                    .eq('id', userDetails?.id)
                }
              }}
            >
              <div className='relative mt-1'>
                <div className='relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none sm:text-sm'>
                  <Combobox.Input
                    className='z-10 w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:outline-none'
                    displayValue={(language) => (language as any).label}
                    onChange={(event) =>
                      setInputLanguageQuery(event.target.value)
                    }
                  />
                  <Combobox.Button className='absolute inset-y-0 right-0 flex items-center pr-2'>
                    <ChevronUpDownIcon
                      className='h-5 w-5 text-gray-400'
                      aria-hidden='true'
                    />
                  </Combobox.Button>
                </div>
                <Transition
                  as={Fragment}
                  leave='transition ease-in duration-100'
                  leaveFrom='opacity-100'
                  leaveTo='opacity-0'
                  afterLeave={() => setInputLanguageQuery('')}
                >
                  <Combobox.Options className='absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg focus:outline-none sm:text-sm'>
                    {filteredInputLanguages.length === 0 &&
                    inputLanguageQuery !== '' ? (
                      <div className='relative cursor-default select-none px-4 py-2 text-gray-700'>
                        Nothing found.
                      </div>
                    ) : (
                      filteredInputLanguages.map((language) => (
                        <Combobox.Option
                          key={language.value}
                          className={({ active }) =>
                            `relative cursor-default select-none py-2 pl-10 pr-4 text-gray-900 ${active ? 'bg-gray-100' : ''}`
                          }
                          value={language}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}
                              >
                                {language.label}
                              </span>
                              {selected ? (
                                <span className='absolute inset-y-0 left-0 flex items-center pl-3 text-gray-900'>
                                  <CheckIcon
                                    className='h-5 w-5'
                                    aria-hidden='true'
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Combobox.Option>
                      ))
                    )}
                  </Combobox.Options>
                </Transition>
              </div>
            </Combobox>
          </div>

          <div className='space-y-1'>
            <label htmlFor='outputLanguage' className='text-sm'>
              Meme output language
            </label>
            <Combobox
              name='outputLanguage'
              value={outputLanguage}
              onChange={async (value) => {
                setOutputLanguage(value)
                setUserDetails({
                  ...userDetails,
                  meme_output_language: value.value,
                })

                await supabase
                  .from('user_details')
                  .update({
                    meme_output_language: value.value,
                  })
                  .eq('id', userDetails?.id)
              }}
              disabled={inputOutputLanguageEqual}
            >
              <div className='relative mt-1'>
                <div className='relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none sm:text-sm'>
                  <Combobox.Input
                    className='z-10 w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:outline-none disabled:opacity-50'
                    displayValue={(language) => (language as any).label}
                    onChange={(event) =>
                      setOutputLanguageQuery(event.target.value)
                    }
                    // disabled={inputOutputLanguageEqual} TODO: Fix this
                  />
                  <Combobox.Button
                    className='absolute inset-y-0 right-0 flex items-center pr-2 disabled:opacity-50'
                    // disabled={inputOutputLanguageEqual} TODO: Fix this
                  >
                    <ChevronUpDownIcon
                      className='h-5 w-5 text-gray-400'
                      aria-hidden='true'
                    />
                  </Combobox.Button>
                </div>
                <Transition
                  as={Fragment}
                  leave='transition ease-in duration-100'
                  leaveFrom='opacity-100'
                  leaveTo='opacity-0'
                  afterLeave={() => setOutputLanguageQuery('')}
                >
                  <Combobox.Options className='absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg focus:outline-none sm:text-sm'>
                    {filteredOutputLanguages.length === 0 &&
                    outputLanguageQuery !== '' ? (
                      <div className='relative cursor-default select-none px-4 py-2 text-gray-700'>
                        Nothing found.
                      </div>
                    ) : (
                      filteredOutputLanguages.map((language) => (
                        <Combobox.Option
                          key={language.value}
                          className={({ active }) =>
                            `relative cursor-default select-none py-2 pl-10 pr-4 text-gray-900 ${active ? 'bg-gray-100' : ''}`
                          }
                          value={language}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}
                              >
                                {language.label}
                              </span>
                              {selected ? (
                                <span className='absolute inset-y-0 left-0 flex items-center pl-3 text-gray-900'>
                                  <CheckIcon
                                    className='h-5 w-5'
                                    aria-hidden='true'
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Combobox.Option>
                      ))
                    )}
                  </Combobox.Options>
                </Transition>
              </div>
            </Combobox>
          </div>

          <div className='space-y-1'>
            <label htmlFor='inputOutputLanguageEqual' className='text-sm'>
              Same input &amp; output language?
            </label>
            <div>
              <Switch
                name='inputOutputLanguageEqual'
                checked={inputOutputLanguageEqual}
                onChange={async (value) => {
                  setInputOutputLanguageEqual(value)

                  if (value) {
                    setOutputLanguage(inputLanguage)
                    setUserDetails({
                      ...userDetails,
                      meme_input_language: inputLanguage.value,
                      meme_output_language: inputLanguage.value,
                    })

                    await supabase
                      .from('user_details')
                      .update({
                        meme_input_language: inputLanguage.value,
                        meme_output_language: inputLanguage.value,
                      })
                      .eq('id', userDetails?.id)
                  }
                }}
                className={`${inputOutputLanguageEqual ? 'bg-indigo-600' : 'bg-gray-300'} relative inline-flex h-[26px] w-[50px] shrink-0 cursor-pointer rounded-full border-2 border-indigo-600 transition-colors duration-200 ease-in-out focus:outline-none`}
              >
                <span
                  aria-hidden='true'
                  className={`${inputOutputLanguageEqual ? 'translate-x-6' : 'translate-x-0'} pointer-events-none inline-block h-[22px] w-[22px] transform rounded-full bg-white shadow-lg transition duration-200 ease-in-out`}
                />
              </Switch>
            </div>
          </div>
        </div>
      </Popover.Panel>
    </Popover>
  )
}

export default LanguageSelector
