export const MemeConstants = {
  initialFontSize: 25,
  initialFontSizeV2: 18,
  topBottomTextPadding: 2,
  headerFooter: {
    initialFontSize: 20,
    fontColor: '#000000',
    fontFamily: 'Arial',
    getLineHeight: (fontSize: number) => fontSize * 1.4
  }
}
