// React
import { Fragment, useMemo, useState } from 'react'

// Next
import Head from 'next/head'
import { useRouter } from 'next/router'

// Components
import Logo from '@/components/blocks/logo'
import { Button } from '@/components//ui/button'
import { Footer } from '@/components/layouts/footer'
import { SideNav } from '@/components/layouts/side-nav'
import LanguageSelector from '@/components/blocks/language-selector'
import { LoggedOutSideNav } from '@/components/layouts/logged-out-side-nav'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'

// Utils
import { supabaseBrowserClient as supabase } from '@/utils/supabase-browser-client'
import {
  useSession,
  useRefreshSession,
  useUserDetails,
} from '@/utils/auth/user-context'

// Headless UI
import { Dialog, Transition } from '@headlessui/react'

// Icons
import {
  AlertTriangleIcon,
  ChevronsUpDownIcon,
  CircleUserRoundIcon,
  MenuIcon,
  PowerIcon,
  XIcon,
} from 'lucide-react'

type LayoutProps = {
  children: React.ReactNode
}

const routeInfo = {
  '/account/my-templates': {
    title: 'My templates',
    description: 'All the templates you have uploaded to Supermeme.ai',
  },
  '/account/my-memes': {
    title: 'All my memes',
    description: "Here's all the memes you've generated using Supermeme.ai",
  },
  '/account/my-prompts': {
    title: 'My prompts',
    description: 'Your past prompts - click on the prompt to generate again',
  },
  '/account/saved-memes': {
    title: 'Starred memes',
    description: 'Your favorite memes created with Supermeme.ai',
  },
  '/meme-generator': {
    title: 'Generate memes with AI',
    description: 'Generate memes using the Supermeme.ai meme generator',
  },
  '/meme-maker': {
    title: 'Make a meme',
    description: 'Create a meme using the Supermeme.ai meme maker',
  },
  '/meme/*': {
    title: 'Make a meme',
    description: 'Create a meme using the Supermeme.ai meme maker',
  },
}

const BaseLayout: React.FC<LayoutProps> = ({ children }) => {
  const session = useSession()
  const [userDetails] = useUserDetails()
  const refreshSession = useRefreshSession()
  const router = useRouter()
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const getHeaderTitle = (): React.ReactNode | null => {
    const routeKey = Object.keys(routeInfo).find(
      (key) => router.pathname.startsWith(key.replace('*', '')) // support wildcard routes
    )

    const { title, description } = routeInfo[routeKey] || {}

    if (!title || !description) return null

    return (
      <div>
        <h1 className='text-2xl font-bold'>{title}</h1>
        <p>{description}</p>
      </div>
    )
  }

  const renderLogoutButton = useMemo(() => {
    return (
      <Popover>
        <PopoverTrigger asChild>
          <div className='flex w-full cursor-pointer items-center space-x-3 outline-none focus:outline-none'>
            <div>
              {userDetails?.avatar_url ? (
                <img
                  className='inline-block h-9 w-9 rounded-full'
                  src={userDetails?.avatar_url}
                  alt='Profile picture'
                />
              ) : (
                <CircleUserRoundIcon
                  className='h-6 w-6 rounded-full'
                  aria-hidden='true'
                />
              )}
            </div>
            <div>
              <p className='text-left text-sm font-medium text-gray-700 group-hover:text-gray-900'>
                {userDetails?.full_name ?? 'Chief meme officer'}
              </p>
            </div>
            <ChevronsUpDownIcon className='h-5 w-5 text-gray-700 group-hover:text-gray-900' />
          </div>
        </PopoverTrigger>
        <PopoverContent>
          <Button
            variant='ghost'
            className='w-full border border-red-600 text-red-600 hover:bg-gray-200 hover:text-red-700'
            onClick={async () => {
              await supabase.auth.signOut()
              refreshSession()
              router.replace('/login')
              setSidebarOpen(false)
            }}
          >
            <PowerIcon
              className='mr-3 h-4 w-4 flex-shrink-0'
              aria-hidden='true'
            />
            Log out
          </Button>
        </PopoverContent>
      </Popover>
    )
  }, [userDetails, refreshSession, router])

  return (
    <>
      <Head>
        <title>Generate Memes with AI | Supermeme.ai</title>
      </Head>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as='div'
            className='relative z-40 md:hidden'
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter='transition-opacity ease-linear duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='transition-opacity ease-linear duration-300'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div className='fixed inset-0 bg-gray-600 bg-opacity-75' />
            </Transition.Child>

            <div className='fixed inset-0 z-40 flex'>
              <Transition.Child
                as={Fragment}
                enter='transition ease-in-out duration-300 transform'
                enterFrom='-translate-x-full'
                enterTo='translate-x-0'
                leave='transition ease-in-out duration-300 transform'
                leaveFrom='translate-x-0'
                leaveTo='-translate-x-full'
              >
                <Dialog.Panel className='relative flex w-full max-w-xs flex-1 flex-col bg-white'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-in-out duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in-out duration-300'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                  >
                    <div className='absolute right-0 top-0 -mr-12 pt-2'>
                      <button
                        type='button'
                        className='ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none'
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className='sr-only'>Close sidebar</span>
                        <XIcon
                          className='h-4 w-4 text-white'
                          aria-hidden='true'
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className='flex flex-1 flex-col overflow-y-auto pb-4 pt-5'>
                    <div className='flex flex-shrink-0 items-center px-4'>
                      <Logo />
                    </div>
                    <nav className='mt-5 flex flex-1 flex-col space-y-8 px-2'>
                      {session && <SideNav setSideBarOpen={setSidebarOpen} />}
                      {!session && <LoggedOutSideNav />}
                    </nav>
                  </div>
                  {session && (
                    <div className='flex flex-shrink-0 border-t border-gray-200 p-4'>
                      {renderLogoutButton}
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
              <div className='w-14 flex-shrink-0'>
                {/* Force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {router.pathname !== '/login' && (
          <div className='hidden md:fixed md:inset-y-0 md:flex md:w-60 md:flex-col'>
            <div className='flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-white'>
              <div className='flex flex-1 flex-col overflow-y-auto pb-4 pt-5'>
                <div className='flex flex-shrink-0 items-center px-4'>
                  <Logo />
                </div>
                <nav className='mt-5 flex flex-1 flex-col space-y-8 bg-white px-2'>
                  {session && <SideNav setSideBarOpen={setSidebarOpen} />}
                  {!session && <LoggedOutSideNav />}
                </nav>
              </div>
              {session && (
                <div className='flex flex-shrink-0 border-t border-gray-200 p-4'>
                  {renderLogoutButton}
                </div>
              )}
            </div>
          </div>
        )}

        <div
          className={`flex min-h-screen flex-1 flex-col ${router.pathname !== '/login' ? 'md:pl-60' : ''}`}
        >
          {router.pathname !== '/login' && (
            <div className='sticky top-0 z-10 bg-white pl-1 pt-1 sm:pl-3 sm:pt-3 md:relative'>
              {process.env.NEXT_PUBLIC_SHOW_MAINTENANCE_BANNER === 'true' && (
                <div className='mr-1 flex items-center justify-center rounded-md bg-gray-800 px-6 py-2.5 sm:mr-3 sm:px-3.5'>
                  <AlertTriangleIcon
                    className='mr-2 h-5 w-5 text-yellow-400'
                    aria-hidden='true'
                  />{' '}
                  <p className='text-center text-base font-bold leading-6 text-white'>
                    <span>
                      We are currently experiencing a high error rate from
                      OpenAI. We are working on a fix.
                    </span>
                  </p>
                </div>
              )}
              <div className='flex w-full items-center justify-between pt-1 md:hidden'>
                <Logo />
                <div className='flex items-center justify-center space-x-2'>
                  {/* mobile view */}
                  <LanguageSelector />
                  <button
                    type='button'
                    className='-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none'
                    onClick={() => setSidebarOpen(true)}
                  >
                    <span className='sr-only'>Open sidebar</span>
                    <MenuIcon className='h-5 w-5' aria-hidden='true' />
                  </button>
                </div>
              </div>
              <div className='hidden flex-1 justify-between px-4 md:flex'>
                <div>{getHeaderTitle()}</div>
                <div className='ml-4 flex items-center md:ml-6'>
                  <LanguageSelector />
                </div>
              </div>
            </div>
          )}

          <main className='min-h-screen'>
            <div
              className={router.pathname !== '/login' ? 'py-8 lg:py-16' : ''}
            >
              <div className='mx-auto px-2'>{children}</div>
            </div>
          </main>

          {router.pathname !== '/login' && <Footer />}
        </div>
      </div>
    </>
  )
}

export { BaseLayout }
