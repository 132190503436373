export const SocialMediaImageDimensions = {
  facebook: {
    width: 1080,
    height: 1920,
  },
  instagram: {
    width: 1080,
    height: 1080,
  },
  linkedin: {
    width: 1920,
    height: 1080,
  },
  x: {
    width: 1600,
    height: 900,
  },
}
