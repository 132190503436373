// Next
import { NextPage } from 'next'
import { AppProps } from 'next/app'

// Components
import { BaseLayout } from '@/components/layouts/base-layout'
import Usetiful from '@/components/scripts/usetiful'
import ErrorBoundary from '@/components/errors/error-boundary'
import GoogleAnalytics from '@/components/scripts/google-analytics'
import Posthog from '@/components/scripts/posthog'

// Utils
import { UserContextProvider } from '@/utils/auth/user-context'

// Styles
import '@/styles/global.css'
import '@radix-ui/themes/styles.css'

// External
import { Toaster } from 'react-hot-toast'
import { Theme } from '@radix-ui/themes'

const App: NextPage<AppProps> = ({ Component, pageProps }: AppProps) => {
  return (
    <Theme
      appearance='light'
      accentColor='violet'
      grayColor='sand'
      scaling='95%'
    >
      <UserContextProvider>
        <ErrorBoundary>
          <BaseLayout>
            <Posthog />
            <GoogleAnalytics />
            <Usetiful />
            <Component {...pageProps} />
            <Toaster
              position='bottom-center'
              reverseOrder={false}
              toastOptions={{
                duration: 5000,
              }}
            />
          </BaseLayout>
        </ErrorBoundary>
      </UserContextProvider>
    </Theme>
  )
}

export default App
