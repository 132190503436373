// React
import React, { useMemo, useState } from 'react'

// Next
import { useRouter } from 'next/router'
import Image from 'next/image'

// Components
import { Dialog, DialogContent, DialogTitle } from '@/components/ui/dialog'
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
} from '@/components/ui/input-otp'

// Utils
import { emailRegex } from '@supermeme-ai/utils'
import { useRefreshSession } from '@/utils/auth/user-context'
import { supabaseBrowserClient as supabase } from '@/utils/supabase-browser-client'

// Types
import { PrimaryColor } from '@supermeme-ai/types'

// External
import axios from 'axios'
import { Ring } from '@uiball/loaders'
import { toast } from 'react-hot-toast'
import { LockIcon, SendIcon } from 'lucide-react'
import { REGEXP_ONLY_DIGITS_AND_CHARS } from 'input-otp'

type LoginFormProps = {
  isDialog: boolean
  redirectUrl?: string
}

const LoginForm: React.FC<LoginFormProps> = ({ isDialog, redirectUrl }) => {
  const router = useRouter()

  const redirect = useMemo(() => {
    if (isDialog && !!redirectUrl) {
      return redirectUrl
    }

    if (isDialog && !redirectUrl) {
      return window.location.pathname
    }

    return router?.query.redirect
      ? String(router.query.redirect)
      : '/text-to-meme-2'
  }, [isDialog, redirectUrl, router?.query.redirect])

  const refreshSession = useRefreshSession()
  const [email, setEmail] = useState('')
  const [otp, setOtp] = useState('')
  const [sendingOtp, setSendingOtp] = useState(false)
  const [otpSent, setOtpSent] = useState(false)
  const [loggingIn, setLoggingIn] = useState(false)

  return (
    <>
      <div className='w-full max-w-[500px] px-0 py-6 md:px-6 md:py-12'>
        <div className='w-full max-w-md space-y-8'>
          <h1 className='pb-6 text-center text-2xl font-bold'>
            <span className='block whitespace-nowrap md:inline'>
              Ready to meet your
            </span>{' '}
            <span className='block whitespace-nowrap md:inline'>
              Meme Officer?
            </span>
          </h1>

          <div className='flex items-center justify-center'>
            <button
              className='text-center'
              onClick={() => {
                supabase.auth.signInWithOAuth({
                  provider: 'google',
                  options: {
                    redirectTo: `${window.location.origin}${redirect}`, // TODO: fix this - does not work
                  },
                })
              }}
            >
              <Image
                alt={'Google Login'}
                src={'/static/google-signin.png'}
                height={59.8}
                width={248.3}
              />
            </button>
          </div>

          <div className='flex items-center justify-center'>
            <div className='mr-3 grow border-t border-gray-300'></div>
            <div className='bg-white px-2 uppercase text-gray-500'>
              <span className='mx-2 font-extrabold'>OR</span>
            </div>
            <div className='ml-3 grow border-t border-gray-300'></div>
          </div>

          <form
            onSubmit={async (evt) => {
              evt.preventDefault()
              if (email && emailRegex.test(email)) {
                setSendingOtp(true)

                try {
                  await axios.post('/api/account/email-is-valid', { email })
                  await supabase.auth.signInWithOtp({
                    email,
                    options: {
                      emailRedirectTo: `${window.location.origin}${redirect}`,
                    },
                  })

                  setOtp('')
                  setOtpSent(true)
                  toast.success(
                    'A one-time pin was sent to your email. Please enter the pin to log in.'
                  )
                } catch (error) {
                  toast.error('Please enter a valid email to log in.')
                } finally {
                  setSendingOtp(false)
                }
              } else {
                toast.error('Please enter a valid email to log in.')
              }
            }}
          >
            <div className='space-y-4'>
              <div className='space-y-2 text-center'>
                <label
                  className='text-center text-lg font-semibold'
                  htmlFor='email'
                >
                  Log in with a one-time pin
                </label>
                <input
                  id='email'
                  type='email'
                  className='default-input'
                  placeholder='Email address'
                  value={email}
                  onChange={(evt) => setEmail(evt.target.value)}
                />
              </div>
              <button
                className='primary-button-outline mt-4 w-full'
                type='submit'
              >
                {sendingOtp ? (
                  <Ring size={18} color={PrimaryColor} />
                ) : (
                  <SendIcon className='h-4 w-4' />
                )}
                <span>Send one-time pin</span>
              </button>
              <button
                type='button'
                className='w-full text-center text-sm text-gray-500 underline hover:text-gray-700'
                onClick={() => setOtpSent(true)}
              >
                Show one-time pin input
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* One-time pin dialog */}
      <Dialog open={otpSent} onOpenChange={() => setOtpSent(false)}>
        <DialogContent className='sm:max-w-[425px]'>
          <DialogTitle className='text-center'>
            Log in with one-time pin
          </DialogTitle>
          <div className='mt-8'>
            <form
              className='space-y-4'
              onSubmit={async (evt) => {
                evt.preventDefault()
                setLoggingIn(true)

                try {
                  const { error } = await supabase.auth.verifyOtp({
                    email: email,
                    token: otp.replace(/\s/g, ''),
                    type: 'email',
                  })

                  if (error) {
                    throw error
                  }

                  refreshSession()
                  setOtpSent(false)
                  router.push(redirect)
                } catch (error) {
                  toast.error('Invalid one-time pin. Please try again.')
                } finally {
                  setLoggingIn(false)
                }
              }}
            >
              <div className='m-auto space-y-2'>
                <label className='text-center text-base' htmlFor='otp'>
                  Please enter the one-time pin you received by email
                </label>

                <div className='flex flex-row justify-center'>
                  <InputOTP
                    maxLength={6}
                    value={otp}
                    onChange={(value) => setOtp(value)}
                    pattern={REGEXP_ONLY_DIGITS_AND_CHARS}
                    autoFocus
                  >
                    <InputOTPGroup>
                      <InputOTPSlot index={0} />
                      <InputOTPSlot index={1} />
                      <InputOTPSlot index={2} />
                    </InputOTPGroup>
                    <InputOTPSeparator />
                    <InputOTPGroup>
                      <InputOTPSlot index={3} />
                      <InputOTPSlot index={4} />
                      <InputOTPSlot index={5} />
                    </InputOTPGroup>
                  </InputOTP>
                </div>
              </div>

              <button className='primary-button-solid w-full'>
                {loggingIn ? (
                  <Ring size={18} color='white' />
                ) : (
                  <LockIcon className='h-4 w-4 text-white' />
                )}
                <span>Log in</span>
              </button>
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export { LoginForm }
