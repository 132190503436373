// React
import React from 'react'

// Components
import { Button } from '@/components/ui/button'
import { LoginForm } from '@/components/blocks/login-form'
import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog'

// External
import { RocketIcon } from 'lucide-react'

const LoggedOutSideNav: React.FC = () => {
  return (
    <>
      <div className='flex h-full flex-col items-center justify-center space-y-4'>
        <p className='px-2 text-center font-semibold'>
          Use AI to generate your meme captions automagically.
        </p>

        <Dialog>
          <DialogTrigger asChild>
            <Button variant='outline' className='w-full'>
              <RocketIcon className='mr-2 h-4 w-4' />
              <span>Log in</span>
            </Button>
          </DialogTrigger>
          <DialogContent className='sm:max-w-[425px]'>
            <LoginForm isDialog={true} />
          </DialogContent>
        </Dialog>
      </div>
    </>
  )
}

export { LoggedOutSideNav }
