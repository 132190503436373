import React from 'react'
import Head from 'next/head'
import {
  ArrowPathIcon,
  ArrowTopRightOnSquareIcon,
} from '@heroicons/react/24/outline'

const ErrorComponent: React.FC = () => {
  return (
    <>
      <Head>
        <title>Error | Supermeme.ai</title>
      </Head>
      <div className='flex h-[70vh] flex-col items-center justify-center'>
        <h2 className='mb-8 text-center text-2xl font-bold'>
          Uh-oh! There&apos;s an unexpected error. Please try again!
        </h2>
        <div className='mt-10 flex items-center justify-center space-x-6'>
          <button
            className='primary-button-solid'
            type='button'
            onClick={() => {
              window.location.reload()
            }}
          >
            <ArrowPathIcon className='h-5 w-5' />
            <span>Refresh</span>
          </button>
          <a
            className='primary-button-outline'
            target={'_blank'}
            href='https://www.supermeme.ai/contact'
            rel='noreferrer noopener'
          >
            <ArrowTopRightOnSquareIcon className='h-5 w-5' />
            <span>Contact Support</span>
          </a>
        </div>
      </div>
    </>
  )
}

export default ErrorComponent
