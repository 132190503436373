export const SupportedFonts = (isDesktop: boolean): {
  label: string,
  fontFamily: string,
}[] => {
  return [
    {
      label: 'Arial',
      fontFamily: 'Arial',
    },
    {
      label: 'Comic Sans MS',
      fontFamily: 'Comic Sans MS',
    },
    {
      label: 'Courier New',
      fontFamily: 'Courier New',
    },
    {
      label: 'Impact (classic meme font)',
      fontFamily: getDefaultFontFamily(isDesktop),
    },
    {
      label: 'Inter',
      fontFamily: 'Inter,system-ui,sans-serif,Arial,Helvetica',
    },
    {
      label: 'Roboto',
      fontFamily: 'Roboto',
    },
    {
      label: 'Times New Roman',
      fontFamily: 'Times New Roman',
    },
  ]
}

export const getDefaultFontFamily = (isDesktop: boolean): string => {
  return isDesktop ?
    'Impact, Franklin Gothic Bold, Charcoal, Helvetica Inserat, Bitstream Vera Sans Bold, Arial Black, sans serif'
    :
    'Anton'
}
